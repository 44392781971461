import React from 'react';
import './about.css';

function AboutPage() {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1>About Our School</h1>
        <p>Welcome to our school website! Here, you can learn more about our institution, our mission, and our values.</p>
        
        <div className="about-section">
          <h2>Our Mission</h2>
          <p>Our mission is to provide quality education to students and prepare them for success in their academic and personal lives.</p>
        </div>
        
        <div className="about-section">
          <h2>Our Vision</h2>
          <p>Our vision is to create a nurturing environment where students can grow intellectually, emotionally, and socially.</p>
        </div>
        
        <div className="about-section">
          <h2>Our Values</h2>
          <ul>
            <li>Excellence: We strive for excellence in all that we do.</li>
            <li>Respect: We respect each other's differences and perspectives.</li>
            <li>Integrity: We uphold honesty, integrity, and ethical behavior.</li>
            <li>Community: We foster a sense of belonging and community among students, staff, and parents.</li>
          </ul>
        </div>
        
        <div className="about-section">
          <h2>Our History</h2>
          <p>Our school was founded in [year] by [founder's name]. Since then, we have been dedicated to providing high-quality education to students in our community.</p>
        </div>
        
        <div className="about-section">
          <h2>Our Facilities</h2>
          <p>Our school is equipped with state-of-the-art facilities, including classrooms, laboratories, libraries, sports facilities, and more.</p>
        </div>
        
        <div className="about-section">
          <h2>Meet Our Team</h2>
          <p>Our school is staffed by a team of dedicated and experienced educators who are committed to the success of our students.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;