import React from 'react';
import sdm_1 from '../assets/sdm_1.jpg';
import './home.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';

function Home() {
    return (
        <div>
            <div id="carouselExampleAutoplaying1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active corousel-mb">
                        <img src={sdm_1} className="d-block img-fluid img-main" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={sdm_1} className="d-block img-fluid img-main" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying1" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='justify-content-center align-items-center p-4'>
                <div className='card-container mb-3'>
                    <h3>Our Departments</h3>
                </div>
                <div className='card-container'>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Information Technology</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Pharmaceuticals</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Law</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Science</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-light padd-40'>
                <div className='card-container2'>
                    <h3>Our Amenities</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur, cupiditate! Distinctio, unde! Nesciunt facere necessitatibus officiis veniam ad quidem debitis!</p>
                </div>
                <div className='card-container pt-4'>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Conference Room</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Well Equipped Library</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Cafeteria/Mess</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Sports Facilities</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Accommodation</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={sdm_1} alt="a" className='card-image' />
                        <div className='card-content'>
                            <h3>Transportation</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae aut qui facere dicta repellat dolor perspiciatis quos accusantium. Earum corrupti vitae adipisci nobis repudiandae quia, laborum quod at minus repellendus!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='padd-40'>
                <div className='card-container2'>
                    <h3>Our Courses</h3>
                </div>
                <div className='card-container3 pt-4'>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>BA</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>BCA</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>B.Sc</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>B.Com</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>LLB</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>B.Pharm</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>D.Pharm</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>MA</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>M.Sc</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>B.Ed</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>BTC</h2>
                        </div>
                    </div>
                    <div className='card3'>
                        <div className='card-text'>
                            <h2>M.Ed</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='padd-40 bg-light'>
                <div className='card-container4 pt-4'>
                    <div className='card4'>
                        <div className='card-text'>
                            <AccountCircleIcon style={{ fontSize: 50 }} />
                            <h3>1000+</h3>
                            <p>Students</p>
                        </div>
                    </div>
                    <div className='card4'>
                        <div className='card-text'>
                            <GroupsIcon style={{ fontSize: 50 }} />
                            <h3>40</h3>
                            <p>Teachers</p>
                        </div>
                    </div>
                    <div className='card4'>
                        <div className='card-text'>
                            <AutoStoriesIcon style={{ fontSize: 50 }} />
                            <h3>15</h3>
                            <p>Courses</p>
                        </div>
                    </div>
                    <div className='card4'>
                        <div className='card-text'>
                            <EmojiEventsIcon style={{ fontSize: 50 }} />
                            <h3>50+</h3>
                            <p>Awards Won</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;