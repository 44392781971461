import React from 'react';
import './style.css';
import Phone from '@mui/icons-material/Phone';
import Email from '@mui/icons-material/Email';
import Address from '@mui/icons-material/LocationCity';
import Facebook from '@mui/icons-material/Facebook';
import Twitter from '@mui/icons-material/Twitter';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Instagram from '@mui/icons-material/Instagram';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section about">
                    <h2>About Us</h2>
                    <p>Your school's description goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in sem eget justo lacinia accumsan.</p>
                    <div className="contact">
                        <p><Address /> Address: 123 Main Street, City, Country</p>
                        <p><Email /> Email: mraabid01@gmail.com</p>
                        <p><Phone /> Phone: +91 8795524175</p>
                    </div>
                </div>
                <div className="footer-section links">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/courses">Courses</a></li>
                        <li><a href="/admissions">Admissions</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div className="footer-section social">
                    <h2>Follow Us</h2>
                    <div className="social-icons">
                        <a href="#"><Facebook /></a>
                        <a href="#"><Twitter /></a>
                        <a href="#"><Instagram /></a>
                        <a href="#"><LinkedIn /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; {new Date().getFullYear()} SHARDA DEVI MAHAVIDYALAY KDEREPUR BADLAPUR JAUNPUR. All rights reserved. Designed by <a href="#" style={{textDecoration: "none", color: "black"}}>Aabid Husain</a>
            </div>
        </footer>
    );
}

export default Footer;