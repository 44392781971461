import React, { useState, useRef } from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import logo from '../assets/image.png';
import 'bootstrap';
import RunningText from '../utility/running_text';

function Header() {
    const descText = "Sharda Devi Mahavidhyalay is a prominent educational institution located in the heart of the city. It offers a wide range of undergraduate and postgraduate courses in various disciplines such as arts, science, commerce, and management. The college is known for its excellent faculty, state-of-the-art facilities, and vibrant campus life. It is committed to providing quality education and shaping the future of its students.";

    const offcanvasRef = useRef(null);

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleMouseEnter = () => {
        setMenuOpen(true);
    };

    const handleMouseLeave = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <div className='container-fluid running-text'>
                <nav className='d-flex ms-2 me-2 mt-1 mb-1' style={{ overflow: "hidden", padding: "0" }}>
                    <div style={{ overflow: "hidden", padding: "0" }}>
                        <RunningText text={descText} />
                    </div>
                </nav>
            </div>
            <nav className="navbar sticky-top border-bottom shadow justify-content-center parent-nav">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={logo} alt="Logo" width="100" height="100" className="d-inline-block" />
                            <div className="d-flex flex-column justify-content-center align-items-center ms-lg-3">
                                <a className="navbar-brand fw-bold siteName" href="#">SHARDA DEVI MAHAVIDYALAY KDEREPUR BADLAPUR JAUNPUR</a>
                                <p className="mb-0" style={{ fontSize: "0.9rem" }}>Affiliated by - Veer Bahadur Singh Purvanchal University, Jaunpur</p>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="navbar navbar-expand-lg container-fluid" style={{ margin: "0", backgroundColor: "#a3946f", padding: "5px" }}>
                    <span className="navbar-toggler" onClick={toggleMenu} style={{ cursor: "pointer", color: "#fff" }}>
                        <span className='fa fa-bars' />
                    </span>

                    <div className={`offcanvas offcanvas-end${menuOpen ? ' show' : ''}`} style={{ backgroundColor: "#a3946f", color: "#000", width: "80%" }} ref={offcanvasRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <div className="offcanvas-header" style={{ padding: "10px", backgroundColor: "#947457", justifyContent: "space-between" }}>
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel" style={{ color: "white", backgroundColor: "transparent" }}>Menu Bar</h5>
                            <button type="button" className="btn-close" onClick={toggleMenu}></button>
                        </div>
                        <div className="navbar-nav" style={{ paddingInline: "0.4rem", paddingBlock: "0.3em" }}>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="active" to="/">Home</NavLink>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="active" to="gallery">Gallery</NavLink>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="active" to="about">About us</NavLink>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="active" to="contact">Contact us</NavLink>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-pad-start fw-medium text-deco-none text-col" href="/" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Menu 1
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                                    <li>
                                        <NavLink className="dropdown-item" style={{ backgroundColor: "white", color: "#000" }} activeClassName="active" to="gallery">
                                            <span className="icon">&#128218;</span> 
                                            Gallery
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" style={{ backgroundColor: "white", color: "#000" }} activeClassName="active" to="about">
                                            <span className="icon">&#128218;</span> 
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" style={{ backgroundColor: "white", color: "#000" }} activeClassName="active" to="contact">
                                            <span className="icon">&#128218;</span> 
                                            Contact Us
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-pad-start fw-medium text-deco-none text-col" href="/" id="coursesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Menu 2
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="coursesDropdown">
                                    <li>
                                        <NavLink className="dropdown-item" style={{ backgroundColor: "white", color: "#000" }} activeClassName="active" to="gallery">
                                            <span className="icon">&#128218;</span> 
                                            Gallery
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" style={{ backgroundColor: "white", color: "#000" }} activeClassName="active" to="about">
                                            <span className="icon">&#128218;</span> 
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="dropdown-item" style={{ backgroundColor: "white", color: "#000" }} activeClassName="active" to="contact">
                                            <span className="icon">&#128218;</span> 
                                           Contact Us
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;