import { Outlet } from 'react-router-dom';
import './App.css';
import Headers from './core/header';
import Footer from './core/footer';

function App() {
  return (
    <>
    <Headers />
    <Outlet />
    <Footer />
    </>
  );
}

export default App;
