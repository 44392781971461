// UserModel.js

class User {
    constructor(stu_lg_id, stu_name, email , stu_img) {
      this.stu_lg_id = stu_lg_id;
      this.stu_name = stu_name;
      this.email = email;
      this.stu_img = stu_img;
    }
  }
  
export default User;