import React from 'react';
import './contact.css';

function ContactUs() {
  return (
    <div className="contact-container">
      <div className="contact-content">
        <h1>Contact Us</h1>
        <p>We'd love to hear from you. Please feel free to contact us using the information below:</p>
        
        <div className="contact-info">
          <h2>School Name</h2>
          <p><strong>Address:</strong> Prayagraj Road Karerepur Badlapur Jaunpur</p>
          <p><strong>Phone:</strong> +91 8795524175</p>
          <p><strong>Email:</strong> mraabid01@gmail.com</p>
        </div>
        
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" required></textarea>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
