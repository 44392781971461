import React, { useState, useEffect } from 'react';
import './gallery.css';
import UserModel from '../model/user-model';

const Gallery = () => {

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('https://api.lifeguidance.in/auth/students.php');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                const fetchedUsers = data.map(user => new UserModel(user.stu_lg_id, user.stu_name, user.email, user.stu_img));
                setUsers(fetchedUsers);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    if (isLoading) {
        return <center className='p-5'> <div>Loading...</div></center>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="gallery-container">
            <h1>Gallery</h1>
            <div className="image-grid">
                {users.map((userList, index) => (
                    <div key={index} className="image-item" style={{color: "black"}}>
                        <img src={userList.stu_img} alt={`Image ${userList.stu_img}`} />
                        <h5>{userList.stu_name}</h5>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;
