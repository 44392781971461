import React, { useState, useEffect } from 'react';

const RunningText = ({ text }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition((prevPosition) => (prevPosition + 1) % text.length);
    }, 100); // Adjust speed as needed

    return () => clearInterval(intervalId);
  }, [text.length]);

  return (
    <span style={{ textWrap: "nowrap" }}>
      {text.slice(position, text.length)}
      {text.slice(0, position)}
    </span>
  );
};

export default RunningText;